import React, {useCallback} from "react";
import {observer} from "mobx-react-lite";
import subscription from "@assets/images/subscription.png";
import {useNavigate} from "react-router-dom";
import {SubscribePage} from "../index";
import {useStores} from "@hooks/useStores";
import {PageRoutes} from "../../../constants";
import {ConnectionStep, SubscriptionFlow} from "@stores/account-store";

const SubscribePlans = observer(() => {
  const navigate = useNavigate();
  const {accountStore} = useStores();

  const list = [
    {
      icon: '💎',
      title: 'Unique algorithm detects high-yield tokens',
    },
    {
      icon: '🤖',
      title: 'Social - media AI analytic',
    },
    {
      icon: '🐋',
      title: 'Analysis Major players and their behavior',
    },
    {
      icon: '👨‍💻',
      title: 'Fit for novice and experienced investors',
    },
    {
      icon: '🔍',
      title: 'Private Gem hunting experts community',
    },
    {
      icon: '🎩',
      title: 'All what you need in one place',
    },
  ];

  const handleSubscribe = useCallback((page: SubscribePage) => {
    if (page === SubscribePage.LIFETIME || accountStore.isLoggedIn) {
      navigate(`/subscribe/${page}`);
    } else {
      accountStore.setSubscriptionFlow(page as unknown as SubscriptionFlow);
      accountStore.setConnectionStep(ConnectionStep.SIGNUP);
    }
  }, [navigate, accountStore]);

  return (
    <>
      <div className="row">
        <img src={subscription} alt="uAnalytics Premium" className="wd-100p px-0"/>
      </div>

      <div className="tx-28 lh-2 tx-light tx-left mb-3 mt-4">
        Unlock full<br />
        power of <span className="tx-primary">USHI</span>
      </div>

      <div>
        {list.map(({icon, title}, index) => (
          <div className="d-flex flex-row align-items-center mb-2" key={`ushi-premium-list-${index}`}>
            <span className="tx-24 me-2">{icon}</span>
            <span className="tx-muted">{title}</span>
          </div>
        ))}
      </div>

      <div className="mt-4">
        {/*<div className="tx-muted mb-2">*/}
        {/*  $299.99/3 months*/}
        {/*</div>*/}
        {/*<button*/}
        {/*  className="btn btn-primary mb-2 tx-olney wd-100p"*/}
        {/*  onClick={() => handleSubscribe(SubscribePage.THREE_MONTHS)}*/}
        {/*>*/}
        {/*  Continue*/}
        {/*</button>*/}

        {/*<button*/}
        {/*  className="btn btn-dark bg-semi-transparent-2 d-flex justify-content-between tx-olney wd-100p tx-primary mb-2"*/}
        {/*  onClick={() => handleSubscribe(SubscribePage.MONTHLY)}*/}
        {/*>*/}
        {/*  <span>Subscribe monthly</span>*/}
        {/*  <span>$149.99</span>*/}
        {/*</button>*/}

        <button
          className="btn btn-primary d-flex justify-content-between tx-olney wd-100p"
          // className="btn btn-dark bg-semi-transparent-2 d-flex justify-content-between tx-olney wd-100p tx-primary"
          onClick={() => handleSubscribe(SubscribePage.LIFETIME)}
        >
          <span>Lifetime Access</span>
          <span>99 000 000 USHI</span>
        </button>
      </div>
    </>
  );
});

export default SubscribePlans;
